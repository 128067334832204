
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//audits routes
			{
				path: '/audits/:fieldName?/:fieldValue?',
				name: 'auditslist',
				component: () => import('./pages/audits/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/audits/view/:id', 
				name: 'auditsview', 
				component: () => import('./pages/audits/view.vue'), 
				props: true
			},
		

//bancos routes
			{
				path: '/bancos/:fieldName?/:fieldValue?',
				name: 'bancoslist',
				component: () => import('./pages/bancos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/bancos/view/:id', 
				name: 'bancosview', 
				component: () => import('./pages/bancos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/bancos/add', 
				name: 'bancosadd', 
				component: () => import('./pages/bancos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/bancos/edit/:id', 
				name: 'bancosedit', 
				component: () => import('./pages/bancos/edit.vue'), 
				props: true
			},
		

//canchafotos routes
			{
				path: '/canchafotos/:fieldName?/:fieldValue?',
				name: 'canchafotoslist',
				component: () => import('./pages/canchafotos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/canchafotos/view/:id', 
				name: 'canchafotosview', 
				component: () => import('./pages/canchafotos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/canchafotos/add', 
				name: 'canchafotosadd', 
				component: () => import('./pages/canchafotos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/canchafotos/edit/:id', 
				name: 'canchafotosedit', 
				component: () => import('./pages/canchafotos/edit.vue'), 
				props: true
			},
		

//canchas routes
			{
				path: '/canchas/:fieldName?/:fieldValue?',
				name: 'canchaslist',
				component: () => import('./pages/canchas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/canchas/view/:id', 
				name: 'canchasview', 
				component: () => import('./pages/canchas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/canchas/add', 
				name: 'canchasadd', 
				component: () => import('./pages/canchas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/canchas/edit/:id', 
				name: 'canchasedit', 
				component: () => import('./pages/canchas/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/canchas/fotos/:id', 
				name: 'canchasfotos', 
				component: () => import('./pages/canchas/fotos.vue'), 
				props: true
			},
		

//comercialcampanias routes
			{
				path: '/comercialcampanias/:fieldName?/:fieldValue?',
				name: 'comercialcampaniaslist',
				component: () => import('./pages/comercialcampanias/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/comercialcampanias/view/:id', 
				name: 'comercialcampaniasview', 
				component: () => import('./pages/comercialcampanias/view.vue'), 
				props: true
			},
		
			{ 
				path: '/comercialcampanias/add', 
				name: 'comercialcampaniasadd', 
				component: () => import('./pages/comercialcampanias/add.vue'), 
				props: true
			},
	
			{ 
				path: '/comercialcampanias/edit/:id', 
				name: 'comercialcampaniasedit', 
				component: () => import('./pages/comercialcampanias/edit.vue'), 
				props: true
			},
		

//comercialclientes routes
			{
				path: '/comercialclientes/:fieldName?/:fieldValue?',
				name: 'comercialclienteslist',
				component: () => import('./pages/comercialclientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/comercialclientes/view/:id', 
				name: 'comercialclientesview', 
				component: () => import('./pages/comercialclientes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/comercialclientes/add', 
				name: 'comercialclientesadd', 
				component: () => import('./pages/comercialclientes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/comercialclientes/edit/:id', 
				name: 'comercialclientesedit', 
				component: () => import('./pages/comercialclientes/edit.vue'), 
				props: true
			},
		

//comercialnotas routes
			{
				path: '/comercialnotas/:fieldName?/:fieldValue?',
				name: 'comercialnotaslist',
				component: () => import('./pages/comercialnotas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/comercialnotas/view/:id', 
				name: 'comercialnotasview', 
				component: () => import('./pages/comercialnotas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/comercialnotas/add', 
				name: 'comercialnotasadd', 
				component: () => import('./pages/comercialnotas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/comercialnotas/edit/:id', 
				name: 'comercialnotasedit', 
				component: () => import('./pages/comercialnotas/edit.vue'), 
				props: true
			},
		

//diassemana routes

//documentacion routes
			{
				path: '/documentacion/:fieldName?/:fieldValue?',
				name: 'documentacionlist',
				component: () => import('./pages/documentacion/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/documentacion/view/:id', 
				name: 'documentacionview', 
				component: () => import('./pages/documentacion/view.vue'), 
				props: true
			},
		
			{ 
				path: '/documentacion/add', 
				name: 'documentacionadd', 
				component: () => import('./pages/documentacion/add.vue'), 
				props: true
			},
	
			{ 
				path: '/documentacion/edit/:id', 
				name: 'documentacionedit', 
				component: () => import('./pages/documentacion/edit.vue'), 
				props: true
			},
		

//establecimientobancos routes
			{
				path: '/establecimientobancos/:fieldName?/:fieldValue?',
				name: 'establecimientobancoslist',
				component: () => import('./pages/establecimientobancos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/establecimientobancos/view/:id', 
				name: 'establecimientobancosview', 
				component: () => import('./pages/establecimientobancos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/establecimientobancos/add', 
				name: 'establecimientobancosadd', 
				component: () => import('./pages/establecimientobancos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/establecimientobancos/edit/:id', 
				name: 'establecimientobancosedit', 
				component: () => import('./pages/establecimientobancos/edit.vue'), 
				props: true
			},
		

//establecimientohorarios routes

//establecimientonotas routes
			{
				path: '/establecimientonotas/:fieldName?/:fieldValue?',
				name: 'establecimientonotaslist',
				component: () => import('./pages/establecimientonotas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/establecimientonotas/view/:id', 
				name: 'establecimientonotasview', 
				component: () => import('./pages/establecimientonotas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/establecimientonotas/add', 
				name: 'establecimientonotasadd', 
				component: () => import('./pages/establecimientonotas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/establecimientonotas/edit/:id', 
				name: 'establecimientonotasedit', 
				component: () => import('./pages/establecimientonotas/edit.vue'), 
				props: true
			},
		

//establecimientos routes
			{
				path: '/establecimientos/:fieldName?/:fieldValue?',
				name: 'establecimientoslist',
				component: () => import('./pages/establecimientos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/establecimientos/view/:id', 
				name: 'establecimientosview', 
				component: () => import('./pages/establecimientos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/establecimientos/add', 
				name: 'establecimientosadd', 
				component: () => import('./pages/establecimientos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/establecimientos/edit/:id', 
				name: 'establecimientosedit', 
				component: () => import('./pages/establecimientos/edit.vue'), 
				props: true
			},
		

//geociudades routes
			{
				path: '/geociudades/:fieldName?/:fieldValue?',
				name: 'geociudadeslist',
				component: () => import('./pages/geociudades/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geociudades/view/:id', 
				name: 'geociudadesview', 
				component: () => import('./pages/geociudades/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geociudades/add', 
				name: 'geociudadesadd', 
				component: () => import('./pages/geociudades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geociudades/edit/:id', 
				name: 'geociudadesedit', 
				component: () => import('./pages/geociudades/edit.vue'), 
				props: true
			},
		

//geodepartamentos routes
			{
				path: '/geodepartamentos/:fieldName?/:fieldValue?',
				name: 'geodepartamentoslist',
				component: () => import('./pages/geodepartamentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geodepartamentos/view/:id', 
				name: 'geodepartamentosview', 
				component: () => import('./pages/geodepartamentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geodepartamentos/add', 
				name: 'geodepartamentosadd', 
				component: () => import('./pages/geodepartamentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geodepartamentos/edit/:id', 
				name: 'geodepartamentosedit', 
				component: () => import('./pages/geodepartamentos/edit.vue'), 
				props: true
			},
		

//geopaises routes
			{
				path: '/geopaises/:fieldName?/:fieldValue?',
				name: 'geopaiseslist',
				component: () => import('./pages/geopaises/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geopaises/view/:id', 
				name: 'geopaisesview', 
				component: () => import('./pages/geopaises/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geopaises/add', 
				name: 'geopaisesadd', 
				component: () => import('./pages/geopaises/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geopaises/edit/:id', 
				name: 'geopaisesedit', 
				component: () => import('./pages/geopaises/edit.vue'), 
				props: true
			},
		

//metodospago routes
			{
				path: '/metodospago/:fieldName?/:fieldValue?',
				name: 'metodospagolist',
				component: () => import('./pages/metodospago/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/metodospago/view/:id', 
				name: 'metodospagoview', 
				component: () => import('./pages/metodospago/view.vue'), 
				props: true
			},
		
			{ 
				path: '/metodospago/add', 
				name: 'metodospagoadd', 
				component: () => import('./pages/metodospago/add.vue'), 
				props: true
			},
	
			{ 
				path: '/metodospago/edit/:id', 
				name: 'metodospagoedit', 
				component: () => import('./pages/metodospago/edit.vue'), 
				props: true
			},
		

//permissions routes

//reservapagos routes
			{
				path: '/reservapagos/:fieldName?/:fieldValue?',
				name: 'reservapagoslist',
				component: () => import('./pages/reservapagos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/reservapagos/view/:id', 
				name: 'reservapagosview', 
				component: () => import('./pages/reservapagos/view.vue'), 
				props: true
			},
		

//reservas routes
			{
				path: '/reservas/:fieldName?/:fieldValue?',
				name: 'reservaslist',
				component: () => import('./pages/reservas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/reservas/view/:id', 
				name: 'reservasview', 
				component: () => import('./pages/reservas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/reservas/add', 
				name: 'reservasadd', 
				component: () => import('./pages/reservas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/reservas/edit/:id', 
				name: 'reservasedit', 
				component: () => import('./pages/reservas/edit.vue'), 
				props: true
			},
		

//roles routes

//tipoidentificaciones routes
			{
				path: '/tipoidentificaciones/:fieldName?/:fieldValue?',
				name: 'tipoidentificacioneslist',
				component: () => import('./pages/tipoidentificaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoidentificaciones/view/:id', 
				name: 'tipoidentificacionesview', 
				component: () => import('./pages/tipoidentificaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoidentificaciones/add', 
				name: 'tipoidentificacionesadd', 
				component: () => import('./pages/tipoidentificaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoidentificaciones/edit/:id', 
				name: 'tipoidentificacionesedit', 
				component: () => import('./pages/tipoidentificaciones/edit.vue'), 
				props: true
			},
		

//tiposcancha routes
			{
				path: '/tiposcancha/:fieldName?/:fieldValue?',
				name: 'tiposcanchalist',
				component: () => import('./pages/tiposcancha/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tiposcancha/view/:id', 
				name: 'tiposcanchaview', 
				component: () => import('./pages/tiposcancha/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tiposcancha/add', 
				name: 'tiposcanchaadd', 
				component: () => import('./pages/tiposcancha/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tiposcancha/edit/:id', 
				name: 'tiposcanchaedit', 
				component: () => import('./pages/tiposcancha/edit.vue'), 
				props: true
			},
		

//usuarios routes
			{
				path: '/usuarios/:fieldName?/:fieldValue?',
				name: 'usuarioslist',
				component: () => import('./pages/usuarios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/usuarios/view/:id', 
				name: 'usuariosview', 
				component: () => import('./pages/usuarios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usuariosuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usuariosaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usuariosaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/add', 
				name: 'usuariosadd', 
				component: () => import('./pages/usuarios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/edit/:id', 
				name: 'usuariosedit', 
				component: () => import('./pages/usuarios/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/instructivo', 
				name: 'instructivo', 
				component: () => import('././pages/custom/instructivo.vue'), 
				props: true
			},
	
			{ 
				path: '/pagos_pendientes', 
				name: 'pagos_pendientes', 
				component: () => import('././pages/custom/pagos_pendientes.vue'), 
				props: true
			},
	
			{ 
				path: '/pagos_historicos', 
				name: 'pagos_historicos', 
				component: () => import('././pages/custom/pagos_historicos.vue'), 
				props: true
			},
	
			{ 
				path: '/pagos_pendientes_detalle', 
				name: 'pagos_pendientes_detalle', 
				component: () => import('././pages/custom/pagos_pendientes_detalle.vue'), 
				props: true
			},
	
			{ 
				path: '/listadoreservasporpago', 
				name: 'listadoreservasporpago', 
				component: () => import('././pages/custom/listadoreservasporpago.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
//Email verify routes
			{ 
				path: '/index/verifyemail', 
				name: 'verifyemail', 
				component: () => import('./pages/index/verifyemail.vue'), 
				props: true
			},
			{ 
				path: '/index/emailverified', 
				name: 'emailverified', 
				component: () => import('./pages/index/emailverified.vue'), 
				props: true
			},
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
