
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Alquiler",
    "icon": "pi pi-globe",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/reservas",
        "label": "Reservas",
        "icon": "pi pi-ticket",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/establecimientos",
        "label": "Establecimientos",
        "icon": "pi pi-home",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/pagos_pendientes",
        "label": "Pagos pendientes",
        "icon": "pi pi-dollar",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/reservapagos",
        "label": "Historico de pagos",
        "icon": "pi pi-database",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Publicidad",
    "icon": "pi pi-video",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/comercialclientes",
        "label": "Clientes",
        "icon": "pi pi-briefcase",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/comercialcampanias",
        "label": "Campañas",
        "icon": "pi pi-calendar",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Configuraciones",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/tipoidentificaciones",
        "label": "Tipos identificación",
        "icon": "pi pi-id-card",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/bancos",
        "label": "Bancos",
        "icon": "pi pi-building",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/metodospago",
        "label": "Metodos de pago",
        "icon": "pi pi-dollar",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tiposcancha",
        "label": "Tipos de canchas",
        "icon": "pi pi-inbox",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Sistema",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/usuarios",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/instructivo",
    "label": "Instructivos ",
    "icon": "pi pi-info-circle",
    "iconcolor": "",
    "target": "",
    
  }
],
	estado: [    
{value: "Activo", label: "Activo"},
	{value: "Inactivo", label: "Inactivo"}
    ],
	cubierta: [    
{value: "Si", label: "Si"},
	{value: "No", label: "No"}
    ],
	estadoFisico: [    
{value: "5", label: "Excelente"},
	{value: "4", label: "Bueno"},
	{value: "3", label: "Aceptable"},
	{value: "2", label: "Regular"},
	{value: "1", label: "Malo"}
    ],
	tipo: [    
{value: "llamada", label: "Llamada"},
	{value: "nota", label: "Nota"}
    ],
	tipoCuenta: [    
{value: "Ahorros", label: "Ahorros"},
	{value: "Corriente", label: "Corriente"}
    ],
	hora: [    

    ],
	tipoReserva: [    
{value: "Interna", label: "Interna"},
	{value: "Externa", label: "Externa"}
    ],
	estado2: [    
{value: "En proceso", label: "En proceso"},
	{value: "Reservado", label: "Reservado"},
	{value: "Re programado", label: "Re programado"},
	{value: "Finalizado", label: "Finalizado"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}